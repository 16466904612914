.container {
  border-radius: 6px;
  border: 1px solid var(--colour-surface-200, #e6eaec);
  background: var(--colour-surface-50, #e6eaec);
  width: 100%;
  height: 435px;
}

.empty-state-label {
  margin-bottom: 4px;
  color: var(--colour-text-800, #1b4254);
  font-weight: 600;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.asset-template-radio-container {
  padding: 8px;
  height: 100%;
}

.asset-template-radio-input-wrapper {
  margin-bottom: 0;
  height: 100%;
}

.asset-template-radio-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 8px;
  gap: 8px;
  height: 435px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--colour-surface-300, #c4cbd0);
    border-radius: 4px;
  }

  &.option-selected {
    padding: 0;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.asset-template-radio-input-option {
  width: 100%;
}

.panel {
  border: none;
  box-shadow: none;
  margin-bottom: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

.basic-info-container {
  max-width: 400px;
}

.template-picker-box {
  margin-top: 16px;
  .title {
    font-size: 14px;
    color: var(--colour-text-600, #4d6670);
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 4px;
  }
  .description {
    font-size: 14px;
    color: var(--colour-text-800);
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 16px;
  }
}

.pills-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.section-title {
  font-size: 20px;
  color: var(--colour-text-800);
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 16px;
  margin-top: 0px;
}

.section-description {
  color: var(--colour-text-600, #4d6670);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.07px;
  width: 445px;
}

.container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-right: 8px;
}

.button-toggle-group {
  height: 36px;
  min-width: fit-content;
  white-space: nowrap;
}

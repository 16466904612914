.tagList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;

  & > * {
    flex: 1 0 100px;
    max-width: calc(50% - 8px);
  }
}

.accordion-actions {
  display: flex;
  flex-direction: flex-row;
  gap: 8px;
}

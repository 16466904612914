.create-equipment-button {
  max-height: 36px;
  padding: 8px;

  &[aria-expanded="true"] {
    background-color: var(--colour-primary-500);
  }

  &.light-variant {
    &[aria-expanded="true"] {
      background-color: var(--colour-surface-100);
    }
  }
}

.sectionContainer {
  margin-bottom: 16px;
}

.banner {
  margin-bottom: 16px;

  [aria-label="Banner title"] {
    color: var(--colour-text-600);
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
  }
}

.footerContainer {
  flex: 1;
  display: flex;
  width: 100%;
}

.footerActionsContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
}

.errorCount {
  color: red;
  margin: 0;
}

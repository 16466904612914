.container {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.stepper-container {
  display: unset;
  padding: 24px 16px 24px 24px;
  border-right: 1px solid var(--colour-surface-200, #e6eaec);
  box-sizing: border-box;
}

.form-container {
  box-sizing: border-box;
  min-width: 448px;
  max-width: 100%;
  padding: 24px;
}

.sticky-stepper {
  position: sticky;
  top: 16px;
  z-index: 1000;
}

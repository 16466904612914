.banner {
  margin-bottom: 16px;

  [aria-label="Banner title"] {
    color: var(--colour-text-600);
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
  }
}
